import React, { forwardRef, useRef, useMemo, useImperativeHandle, useEffect, useState } from "react";
import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import { APIGet } from '../../API/APIRequest';
import { useParams } from 'react-router-dom'
import "survey-core/defaultV2.min.css";
import "./resetSurvey.scss"

Serializer.addProperty("dropdown", { name: "adOtherValue:string" });
Serializer.addProperty("dropdown", { name: "dataEndpoint:string" });
Serializer.addProperty("radiogroup", { name: "adOtherValue:string" });
Serializer.addProperty("checkbox", { name: "adOtherValue:string" });
["text", "comment", "date", "datetime", "dropdown"].forEach(type => {
    Serializer.addProperty(type, { name: "answerCode:string" });
});

const SurveyForm = forwardRef(({ surveyCode } , ref) => {
    const [surveyData, setSurveyData] = useState(null);
    const { environment } = useParams();
    const surveyJson = 

        {
        showNavigationButtons: false,
        name: "demographics",
        elements: [
            {
                type: "radiogroup",
                name: "sf_radiobox_1",
                title: "Radiobox with other?",
                choices: [
                    { text: "Male", value: "male", category: "By Birth" },
                    { text: "Female", value: "female", category: "By Birth" },
                    { text: "Non-Binary", value: "nonbinary", category: "Other Identities" },
                    { text: "Prefer not to say", value: "prefer_not_say", category: "Other Identities" }
                ],
                hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true

            },
            {
                type: "dropdown",
                name: "sf_dropdown_1",
                title: "Dropdown with other",
                choices: [
                    { value: "high-school", text: "High School" },
                    { value: "associate-degree", text: "Associate Degree" },
                    { value: "bachelor", text: "Bachelor’s Degree" },
                    { value: "master", text: "Master’s Degree" },
                    { value: "phd", text: "PhD" },

                ],
                hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true
            },

            {
                type: "dropdown",
                name: "sf_dropdown_2",
                title: "What is your current employment status?",
                description: "Simple dropdown",
                titleTooltip: "Get help here",
                choices: [
                    { value: "full-time", text: "Employed full-time" },
                    { value: "part-time", text: "Employed part-time" },
                    { value: "self-employed", text: "Self-employed" },
                    { value: "unemployed", text: "Unemployed" },
                    { value: "student", text: "Student" },
                    { value: "retired", text: "Retired" }
                ],
                isRequired: false
            },
            {
                type: "checkbox",
                name: "sf_checkbox_1",
                title: "Multiple checkbox:",
                choices: [
                    "Apple",
                    "Banana",
                    "Orange",
                    "Grapes",
                    "Mango"
                ],
                hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true
            },
            {
                type: "text",
                name: "sf_textbox_1",
                title: "Text box number:",
                inputType: "number", // Makes it a numeric input
                isRequired: true,
                min: 1, // Minimum value allowed
                max: 120, // Maximum value allowed
                placeHolder: "Enter a number"
            },
            {
                type: "text",
                name: "sf_textbox_2",
                title: "Text box:",
                isRequired: true,
                placeHolder: "Enter a company"
            },
            {
                "type": "dropdown",
                "name": "sf_dropdown_3",
                "title": "Dropdown Y/N",
                "choices": [
                    { "value": "yes", "text": "Yes" },
                    { "value": "no", "text": "No" }
                ],
                "defaultValue": "yes"
            },
            {
                "type": "checkbox",
                "name": "sf_checkbox_2",
                "title": "Checkbox Y/N",
                "choices": [
                    { "value": "yes", "text": "This is a dpa" },

                ],
                "defaultValue": ["yes"],
                isRequired: true,
            },
            {
                "type": "radiogroup",
                "name": "sf_radiobox_2",
                "title": "Radio button Y/N",
                "choices": [
                    { value: "yes", text: "yes" },
                    { value: "no", text: "no" },

                ],
                "defaultValue": ["yes"],
            },
            {
                type: "comment",
                name: "sf_textarea_1",
                title: "Text Area 5 rows",
                isRequired: true,
                placeHolder: "Write here...",
                rows: 5 // Sets the initial height of the textarea
            },
            {
                type: "comment",
                name: "sf_textarea_2",
                title: "Text Area 5 rows with Max length",
                isRequired: true,
                placeHolder: "Write here...",
                maxLength: 500, // Limits input to 500 characters
                rows: 5 // Sets the initial height of the textarea
            },
            {
                "type": "text",
                "name": "sf_phonenumber_1",
                "title": "Enter your phone number",
                "isRequired": true,
                "inputType": "tel",
                "validators": [{
                    "type": "regex",
                    "text": "Only numbers, '+', '(', and ')' are allowed.",
                    "regex": "^[0-9+()\\- ]+$"
                }]
            }

        ],
        showCompletedPage: false,
        showQuestionNumbers: "off",
        completedHtml: "",
        questionErrorLocation: "bottom",
    
    };

    let isHandling = false;
    const handleValueChanged = (sender, options) => {
        if (isHandling) return;
        isHandling = true;
        if (options.name.endsWith("-Comment") 
         //   && (question.getType() === "dropdown" || question.getType() === "radiogroup" || question.getType() === "checkbox")
        )
        {
            const originalField = options.name.replace("-Comment", ""); 
            const question = sender.getQuestionByName(originalField);

            if (question.value === "other") {
                sender.setValue(originalField, question.adOtherValue || question.value);
            }
            else if (Array.isArray(question.value)) {
                if (question.value.includes("other") && !question.value.includes(question.adOtherValue))
                    question.value = [...question.value, question.adOtherValue];
                else if (!question.value.includes("other") && question.value.includes(question.adOtherValue))
                    question.value = question.value.filter(val => val !== question.adOtherValue);
            }
        }
        isHandling = false;
    };

    const handleLoadChoicesFromServer = (survey, options) => {

        // Handle "country" dropdown
        //if (options.question.choicesByUrl.url === "getformcompanies") {
        if (options.question.dataEndpoint) {
            APIGet({
                "controller": "account",
                "action": options.question.dataEndpoint,
                "identifier": surveyCode,
                "environment": environment
            })
                .then(function (response) {
                    let data = response.data.choices;
                    /*options.choices */let choices = data.map(item => ({
                        value: item.name,
                        text: item.name
                    }));
                    options.setItems(choices, response.data.total);
                    options.loaded = true;

                });

            options.waitForResult = true;
        }
        //}
    };

    useEffect(() => {
        const InlineForms = async () => {
            await APIGet({
                "controller": "account",
                "action": "getformbyid",
                "identifier": surveyCode,
                "environment": environment
            })
                .then(function (response) {
                    let survey = response.data.surveyData;
                    const surveyModel = new Model(survey);
                    //surveyModel.onValueChanging.add(handleValueChanging);
                    surveyModel.onValueChanged.add(handleValueChanged);
                    //surveyModel.onLoadChoicesFromServer.add(handleLoadChoicesFromServer);
                    surveyModel.onChoicesLazyLoad.add(handleLoadChoicesFromServer);
                    setSurveyData(surveyModel);
                });

        };

        InlineForms();
    }, []);

    //const surveyModel = useRef(new Model(surveyJson)); // SurveyJS model initialization
    //const surveyModel = useRef(new Model(surveyData)); // SurveyJS model initialization


    //console.log("surveyJs controller: ", surveyData)
    
    useImperativeHandle(ref, () => ({
        validateSurvey: () => surveyData.validate(),
        getSurveyData: () => surveyData.data,
        prefixAnswers: () => {
            const survey = surveyData;
            const questions = survey.getAllQuestions();

            questions.forEach((q) => {
                if (
                    ["text", "comment", "date", "datetime", "dropdown"].includes(q.getType()) &&
                    q.value &&
                    q.answerCode
                ) {
                    const prefix = q.answerCode;

                    if (prefix && q.value && typeof q.value === "string") {
                        q.value = `${prefix}_ad|_${q.value}`;
                    }
                }
            });

            console.log("Prefixed values:", survey.data);
        }
    }));
    
    return surveyData ? <Survey model={surveyData} /> : <p>Loading survey...</p>;
});

export default SurveyForm;
