import React, { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Buttons/Button';
import '../../../i18n';
import * as ReactDOM from 'react-dom';
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { GetConfiguration, GetCurrentBrand } from '../../Configuration/ConfigurationManager';
import { APIGet, APIPost, APIRedirect } from '../../API/APIRequest'
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { LogWebActivity } from '../../Webactivity/LogWebActivity';
import { Loading } from '../../Utilities/Loading';
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl';
import { useAuth } from '../AuthenticationProvider';
import Input from '../../UI/Inputs/Input';
import InputWrapper from '../../UI/Inputs/InputWrapper';
import Checkbox from '../../UI/Checkbox/Checkbox';
import useBodyClass from '../../../Hooks/useBodyClass';
import usePageTitle from '../../../Hooks/usePageTitle';
import ReactHtmlParser from 'html-react-parser';
import './Register.css';
import { Password } from '../PasswordValidations/PasswordValidations';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import useBrandingData from '../../../Hooks/useBrandingData';
import { emailRegexUtils, nameRegexUtils } from '../../Utilities/EmailRegex';
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';
import { APIUrl } from '../../Utilities/APIUrl';
import { RecaptchaError } from '../../Utilities/RecaptchaError';
import Transition from '../../UI/Transition/Transition';
import DOMPurify from 'dompurify';
import SurveyForm from '../../UI/SurveyForms/SurveyForm';
  

export function Register(props) {

    useBodyClass(['register', props.brandingElement?.bodyClass]);
    usePageTitle(`Create your account - ${props.brandingElement?.id}`);

    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checking, setChecking] = useState(false);
    const [isNotTrusted, setIsNotTrusted] = useState('');
    const { environment } = useParams();
    const auth = useAuth();
    const [fieldChanged, setFieldChanged] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [buttonEvent, setButtonEvent] = useState(false);
    const [lastName, setLastName] = useState("");
    const [dpa, setDpa] = useState([]);
    const [cleanDpaQuestionHtml, setCleanDpaQuestionHtml] = useState([]);
    const [userPassword, setUserPassword] = useState("");
    const [registered, setRegistered] = useState("false");
    const [userEmail, setUserEmail] = useState(localStorage.getItem('email'));
    const [pswValid, setPswValid] = useState("false");
    const [isChecked, setIsChecked] = useState("false")
    const [buttonState, setButtonState] = useState("initial");
    const [IsBlank, setIsBlank] = useState({ email: false, password: false, lastName: false, firstName: false, dpa: false })
    const [IsInvalid, setIsInvalid] = useState({ lastName: false, firstName: false})
    const createUrl = useCreateUrl();
    const navigate = useNavigate();
    const headerRef = useRef(null);
    const formRef=useRef(null);
    const [isDpaQuestionTextBlank, setIsDpaQuestionTextBlank] = useState(false);
    const [dpaValidationConfig , setDpaValidationConfig] = useState(false);
    // const { getBrandedHtml } = useBrandingData(props.brandingElement ? props.brandingElement : "");
    // const recaptchaKey=getBrandedHtml('reCAPTCHAkey')
    const [reCaptchaError, setReCaptchaError]= useState(false)
    const [surveyData, setSurveyData] = useState({});
    const emailRegex = emailRegexUtils;
    const nameRegex = nameRegexUtils;
    const surveyRef = useRef(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [surveycode, setSurveyCode] = useState(null);
    let [searchParams] = useSearchParams();
    let surveycodeURLParam = searchParams.get("surveycode");
    //recaptcha verification
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }try{

            const recaptchaToken = await executeRecaptcha('register');
            return recaptchaToken;

        } catch(err){
            //in case of invalid recaptcha key
            return "invalidKey";
        }
      
        // we will use the token in recaptchaVerified()
    }, [executeRecaptcha]);

 
    const ReturnUrl = () => {
        let params = (new URL(document.location)).searchParams;
        return params.get("ReturnUrl");
    }

    const loadDPA = async () => {
        await APIGet({
            "controller": "auth0",
            "action": "getregistrationdpa",
            "environment": environment
        })
            .then(function (response) {

                let dpa=response.data.dpaquestion;
                setDpa(dpa);
                cleanDpaText(dpa?.questiontext);
                checkForDpaQuestionText(dpa?.questiontext) //check for blank question text
                setIsChecked(dpa?.defaultticked); //this is also async..so may take time so directly pass in the function.
                setIsLoading(false)
                
            })
            .catch(function (error) {
                // handle error
                setIsLoading(false);
                console.log(error);
            })
    }

    const checkForDpaQuestionText=(dpa)=>{
       
        let div = document.createElement('div');
        
        div.innerHTML = dpa;
        
        let textContent = div.textContent;  
        
        let trimmedText = textContent.trim();   //if html is present and has a blank
        
        if(trimmedText===""){
            setIsDpaQuestionTextBlank(true);
        }

    }

    const cleanDpaText=(dpa)=>{
        const cleanDpaHtml = DOMPurify.sanitize(dpa, {
            ALLOWED_ATTR: ['target', 'href', 'class', 'id', 'style'], // Add any other allowed attributes here
          });

        setCleanDpaQuestionHtml(cleanDpaHtml); 
    }


    useEffect(() => {

        setButtonState("initial");
        return () => {
        setButtonState("initial")     //when we leave the page it sets button state to initial
        };
    }, [window.location.href])

    useEffect(() => {
        loadDPA();
        getFrontendConfiguration();
        getCurrentBrandDefaultDemograpicsForm();
        headerRef.current?.focus();
    }, [])

    useLayoutEffect(() => {

        setTimeout(() => {
            if (localStorage.getItem("email")) {
                setUserEmail(localStorage.getItem("email"))
                auth.CheckRegisteredAccount(localStorage.getItem("email")).then((result) => {
                if (result.status === "email_found") {
                   setRegistered("true");
                 }
                }).catch((errors) => {

                });
            }
        }, 1500)
    }, [])

    //recaptcha verification
    const recaptchaVerified = async() => {
        const recaptchaToken = await handleReCaptchaVerify();
        //if recaptcha is incorrect
        if(recaptchaToken === "invalidKey"){
            return true;  //it should work without the recaptcha as well and will return true in case of invalid key
        }
           
           let bodyFormData = new FormData();
           bodyFormData.append('token', recaptchaToken);

           const res = await APIPost({
               "controller": "security",
               "action": "verifyrecaptcha",
               "environment" : environment,
               data: bodyFormData,
           }).catch((errors) => { ProcessErrors(errors)})

           if (res.data.success) {
               if (res.data.score > 0.5) {
                   return true;    //probable human activity
               }
               else{
                 setReCaptchaError(true)   //probabale bot activity
                 return false;
               } 
           }else{
               return false;
           }
        
    }

    const registerUser = async(event) => {
        event.preventDefault();
       //in case of invalid key or of no-recaptcha value of isHuman will be true, only when we get a valid recaptchaKey, the flow of recaptcha
       //and assessment of activity will begin.
        //recaptcha verification
        let surveyData = null;
        if (surveyRef?.current) {
            surveyRef.current.prefixAnswers();
            surveyData = surveyRef.current.getSurveyData();
            console.log('Survey Data:', surveyData);
            for (let key in surveyData) {
                console.log(key, surveyData[key]);
            }
        }
        setButtonState("loading")  
        setErrors(null);
        if (Validated()) {
            const isHuman= props.hasRecaptcha ? await recaptchaVerified() : true; 

            if(isHuman){
                   
                let controller = "";               
                switch (auth.authProviderType) {
                    case "adnative":
                        controller = "account";
                        break;
                    case "auth0":
                        controller = "auth0";
                        break;
                }               
                     
                let returnUrl = auth.ReturnUrl();

                let bodyFormData = new FormData();
                let userName = userEmail;
                let password = userPassword;
                bodyFormData.append('email', userName);
                bodyFormData.append('password', password);
                bodyFormData.append('firstname', firstName);
                bodyFormData.append('surname', lastName);
                if (surveyData)
                    for (let key in surveyData) {
                        bodyFormData.append(`_srvitem_${key}`, surveyData[key]);
                   
                }
                if (dpa == null) {
                    bodyFormData.append('dpaFlag', false);
                } else {
                    bodyFormData.append('dpaFlag', isChecked);
                }
                bodyFormData.append('returnUrl', returnUrl);
         
                APIPost({
                    "controller": controller,
                    "action": "register",
                    "environment" : environment,
                    data: bodyFormData
                }).then((response) => {
                    let registerResult = response.data;
                    if (registerResult.success) {
                        // let apiUrl = APIUrl({ "path": "/auth0/authenticate" });
                        // apiUrl += "?ReturnUrl=" + returnUrl + "&email=" + result.auth0user.email;
                        // window.location.href = apiUrl;

                        let returnUrl = auth.ReturnUrl();
                        auth.Login(userName, userPassword).then((response) => {
                            let loginResult = response;
                            let returnUri = new URL(returnUrl);
                            if (returnUri.hostname != window.location.hostname) {
                                let ad_ref_token = loginResult.ad_ref_token;
                                sessionStorage.setItem("ad_ref_token", ad_ref_token)
                                if (returnUrl.indexOf('?') >= 0) {
                                    returnUrl += "&";
                                }
                                else {
                                    returnUrl += "?";
                                }
                                returnUrl += "ref=" + ad_ref_token;
                            }
                            IsSafeUrl(returnUrl, environment).then((result) => {
                                if (result) {

                                    // add adref token to returnUrl if request is from outside this host


                                    navigate("/redirect", {
                                        state:
                                            { returnUrl: returnUrl }
                                    })

                                }
                                else {
                                    if (!isNotTrusted) {
                                        setIsNotTrusted(returnUrl);
                                    }
                                }
                            }).catch((errors) => {
                                setErrors(ProcessErrors(errors));
                                setButtonState("initial")
                            });
                        });
                    }
                    else {
                        setErrors(ProcessErrors(registerResult.errors));
                        setButtonState("initial")
                    }
                }).
                catch((error) => {
                    setErrors(ProcessErrors('error.unexpected'));
                    setButtonState("initial")
                });       
            }else{
                setButtonState("initial")   
            }
        }else{
            setButtonState("initial")  
        }
    }


    //when we go back to register page on pressing continue button this will get called.
    const redirect = (event) => {
        event.preventDefault();
        setButtonState("loading")
        let ad_ref_token = sessionStorage.getItem("ad_ref_token")
        //if we have ad_ref_token in the session then only we'd go further
        if (ad_ref_token) {
            let returnUrl = auth.ReturnUrl();
            let returnUri = new URL(returnUrl);
            if (returnUri.hostname != window.location.hostname) {
                if (returnUrl.indexOf('?') >= 0) {
                    returnUrl += "&";
                }
                else {
                    returnUrl += "?";
                }
                returnUrl += "ref=" + ad_ref_token;

                IsSafeUrl(returnUrl, environment).then((result) => {
                    if (result) {

                        // add adref token to returnUrl if request is from outside this host
                        navigate("/redirect", {
                            state:
                                { returnUrl: returnUrl }
                        })                         //navigate??? cause button state is an issue here. (loading)
                        setButtonState("initial")

                    }
                    else {
                        if (!isNotTrusted) {
                            setIsNotTrusted(returnUrl);
                        }
                    }
                }).catch((errors) => {
                    setErrors(ProcessErrors(errors));
                    setButtonState("initial")
                });
            }
        }
    }


    const onTextFieldKeyed = (event) => {
        if ((event.key.match(emailRegex) || []).length > 0) {
            event.preventDefault();
        }
    }


    const handleOnBlur = (e, fieldType) => {
        let value = e.target.value;
        setStateAsync(() => {
            if (value.length < 1) {
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: true }))
                setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: false }))
            }
            else {
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: false }))

                //validating firstName and lastName through regex match
                if (!nameRegex.test(e.target.value)) {
                    setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: true }))
                }
                else {
                    setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: false }))
                }
            }
        })

    }


    //fetching frontend configurations
    const getFrontendConfiguration=()=>{
        APIGet({
           "controller": "configuration",
           "action": "frontendconfiguration",
           "environment": environment,
        }).then((response) => {
            let config= response.data.configuration
            setDpaValidationConfig(config?.dpaValidationConfiguration)

           }).catch((errors)=>{
           console.log("Error in loading frontend configuration")
         })
   }
    const getCurrentBrandDefaultDemograpicsForm = () => {
        if (surveycodeURLParam)
            setSurveyCode(surveycodeURLParam);
        else
            GetCurrentBrand(environment).then((brand) => {
                if (brand?.brandRegistrationDemographicsDefaultForm) {
                    setSurveyCode(brand?.brandRegistrationDemographicsDefaultForm);
                }
            }).
                catch((error) => {
                    //reject(error);
                })
    }

    const Validated = () => {
        let validated = true;
        let emailValue = userEmail;
        setIsBlank(IsBlank => ({ email: false, firstName: false, lastName: false, password: false }))
        if ((emailValue?.match(emailRegex) || []).length <= 0) {
            setIsBlank(IsBlank => ({ ...IsBlank, email: true }))
            validated &= false;
        }
        if (firstName < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, firstName: true }))
            validated &= false;
        }
        else if(!nameRegex.test(firstName)){
            setIsInvalid(IsInvalid => ({ ...IsInvalid, firstName: true }))
            validated &= false;
        }
        if (lastName < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, lastName: true }))
            validated &= false;
        }
        else if(!nameRegex.test(lastName)){
            setIsInvalid(IsInvalid => ({ ...IsInvalid, lastName: true }))
            validated &= false;
        }

        if (userPassword < 1) {
            setIsBlank(IsBlank => ({ ...IsBlank, password: true }))
            validated &= false
        }
        if (pswValid !== "true") {
            validated &= false
        }
        if (surveyRef?.current) {
            const isValid = surveyRef.current.validateSurvey();
            if (!isValid) {
                validated &= false;
            }
        }
    
        if(dpa?.questiontext && !isDpaQuestionTextBlank && dpaValidationConfig && !isChecked){
            setIsBlank(IsBlank => ({ ...IsBlank, dpa: true }))
            validated &= false
        }

        if(dpa?.questiontext && !isDpaQuestionTextBlank && dpaValidationConfig && !isChecked){
            setIsBlank(IsBlank => ({ ...IsBlank, dpa: true }))
            validated &= false
        }

        
        if(!validated){
            focusOnFirstError();
            }
       
        return validated;

    }

    //On submit, focusing on first error causing input field
    const focusOnFirstError=()=>{
        setTimeout(()=>{
            let firstElement = formRef.current?.querySelectorAll("input[invalidclass='Invalid-input'],.sd-question--error input, .sd-question--error textarea")[0];
            firstElement?.focus();
         },200)
        
    }

    if (!auth.ReturnUrl() && !isLoading) {
        if (errors.length == 0) {
            errors.push('error.noreturnurl');
        }
        return (<GenericErrorBox errors={errors} />)
    }

   
    const termsText = props.brandingElement?.termsText || '';


    return (<>
        {reCaptchaError ? <RecaptchaError/>:
        (registered === "true") ?
            <div className="container" id="register-container" aria-live="polite">
                <div className="item-container">
                    <h3 className='module-heading' >{t('register.title')}</h3>
                </div>
                <form onSubmit={redirect} className={isNotTrusted ? "noshow" : ""}>
                    <div className="item-container">
                        <div className="item-wrapper">
                            <p>Your account has been created</p>
                        </div>
                    </div>

                    {buttonState === "initial" ?
                        <Button buttonSize="full-width">{t('login.continuebutton')}</Button>
                        : buttonState === "loading" &&
                        <Button isDisabled="true" hasIcon="updating" buttonSize="full-width">{t('login.continuebutton')}</Button>
                    }
                </form>
            </div>

            : (sessionStorage.getItem("ad_ref_token") === "null" && registered === "false") ?
      
              <div className="container" id="register-container" >
                    
                    <div className="item-container">
                        <h3 className='module-heading focusableHeader' tabIndex="-1" ref={headerRef}>{t('register.title')}</h3>
                    </div>
                    <span aria-live="polite"><GenericErrorBox errors={errors}/></span>
                    <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={isNotTrusted} />
                    <form ref={formRef} onSubmit={registerUser} className={isNotTrusted ? "noshow" : ""}>

                        <div className="item-container">
                            <div className="item-wrapper">
                                <InputWrapperRefacture
                                    label={t('register.emaillabel')}
                                    edit={true}
                                    route={createUrl("login")}
                                    id="email"
                                    name="email"
                                    type="email"
                                    onBlur={(e) => { handleOnBlur(e, "email") }}
                                    inputFieldType="text"
                                    btnText={t('input.edittextlabel')}
                                    invalidClass={IsBlank.email && "Invalid-input"}
                                    aria-invalid={IsBlank.email}
                                    aria-describedby="email-blank-error"
                                    inputSize="full-width"
                                    value={userEmail}
                                    onKeyPress={onTextFieldKeyed}
                                />
                                <span role="alert" id="email-blank-error">{IsBlank.email && <ErrorBoxCS>{t('register.blankemail')}</ErrorBoxCS>}</span>
                            </div>
                        </div>

                         <Transition timer={700} mountAnimation="mount-heightAnimation">
                        {/* password with validations ---component */}
                        <div className="item-container" >
                            <div className="item-wrapper">
                                <Password label={t('register.passwordlabel')}  
                                IsBlank={IsBlank} 
                                setIsBlank={setIsBlank} 
                                password={userPassword} 
                                setUserPassword={setUserPassword} 
                                pswValid={pswValid} 
                                setPswValid={setPswValid} 
                                />
                               
                            </div>
                        </div>
                        </Transition>
                        <Transition timer={700} mountAnimation="mount-heightAnimation">
                         <div className="item-container">
                            <div className="item-wrapper">

                                <InputWrapperRefacture
                                    label={t('register.firstname')}
                                    inputSize="full-width"
                                    id="firstname"
                                    inputFieldType="text"
                                    onBlur={(e) => { handleOnBlur(e, "firstName") }}
                                    specs="text_input"
                                    name="firstname"
                                    value={firstName}
                                    type="text"
                                    invalidClass={(IsBlank.firstName || IsInvalid?.firstName) && "Invalid-input"}
                                    onKeyPress={onTextFieldKeyed}
                                    aria-invalid={(IsBlank.firstName || IsInvalid?.firstName)}
                                    aria-describedby="firstname-blank-error" 
                                    onChange={(e) => { setFirstName(e.target.value) }}
                                />

                                <span role="alert" id='firstname-blank-error'>
                                    {IsBlank.firstName && <ErrorBoxCS>{t('register.blankfirstname')}</ErrorBoxCS>}
                                    {IsInvalid?.firstName && <ErrorBoxCS>{t('register.invalidfirstname')}</ErrorBoxCS>}
                                </span>
                            </div>
                        </div>

                        </Transition>
                        <Transition timer={700} mountAnimation="mount-heightAnimation">

                        <div className="item-container">
                            <div className="item-wrapper">

                                <InputWrapperRefacture
                                    label={t('register.lastname')}
                                    inputSize="full-width"
                                    inputFieldType="text"
                                    id="lastname"
                                    onBlur={(e) => { handleOnBlur(e, "lastName") }}
                                    name="lastname"
                                    type="text"
                                    value={lastName}
                                    invalidClass={(IsBlank.lastName|| IsInvalid?.lastName) && "Invalid-input"}
                                    specs="text-input"
                                    onKeyPress={onTextFieldKeyed}
                                    aria-invalid={(IsBlank.lastName || IsInvalid?.lastName)}
                                    aria-describedby="lastname-blank-error" 
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />

                                <span role="alert" id="lastname-blank-error">
                                    {IsBlank.lastName && <ErrorBoxCS>{t('register.blanklastname')}</ErrorBoxCS>}
                                    {IsInvalid?.lastName && <ErrorBoxCS>{t('register.invalidlastname')}</ErrorBoxCS>}
                                </span>
                            </div>
                        </div>
                            </Transition>
                            {(surveycode) && <>
                                <Transition timer={700}>
                                    <div className="item-container">
                                        <div className="item-wrapper">
                                            <SurveyForm ref={surveyRef} surveyCode={surveycode} />
                                        </div>
                                    </div>
                                </Transition>
                            </>
                            }
                        <Transition timer={500} mountAnimation="mount-heightAnimation">
                        {(dpa?.questiontext && !isDpaQuestionTextBlank) && <>
                            <div className="item-container">
                                <div className="item-wrapper">
                                            <Checkbox label={<div dangerouslySetInnerHTML={{ __html: cleanDpaQuestionHtml }} />} id="dpa-checkbox" name="dpa" checked={isChecked} onChange={() => {setIsChecked(!isChecked)}} />
                                            <span role="alert" id="dpa-blank-error">
                                                {(IsBlank?.dpa && !isChecked) && <ErrorBoxCS>{t('register.blankdpa')}</ErrorBoxCS>}
                                            </span>
                                </div>

                            </div>
                        </>
                        }
                        </Transition>
                        <Transition timer={700} mountAnimation="mount-heightAnimation">
                        <div className="item-container">
                            <div className="item-separator"></div>
                        </div>
                        <div className="item-container">
                            <div className="meta">{ReactHtmlParser(termsText)} </div>
                        </div>
                        </Transition>
                      
                     
                    
                        {buttonState === "initial" ?
                            <Button buttonSize="full-width">{t('register.registerbutton')}</Button>
                            : buttonState === "loading" &&
                            <Button isDisabled="true" hasIcon="updating" buttonSize="full-width">{t('register.registerbutton')}</Button>
                        }
                   
             
                    </form>
                  

                </div>
             
                : sessionStorage.getItem("ad_ref_token") !== "null" && registered === "false" ? <Loading loading={isLoading} /> : ""
        }
        <LogWebActivity />
    </>
    );
}

