import React, { useState,useEffect } from 'react';
import auth0 from 'auth0-js';
import { ProcessErrors } from '../../Utilities/ErrorBox';
import { Loading } from '../../Utilities/Loading';
import { APIGet, APIPost, APIRedirect, getEnvironment, getPage } from '../../API/APIRequest';
import { GetConfiguration } from '../../Configuration/ConfigurationManager';
import { GetADToken, SaveADToken, RefreshADToken, GetAuth0TokenLocal, RemoveADToken, RemoveAuth0Token, SaveAuth0Token } from '../../Utilities/TokenManager'
import { GetCookieName, GetCookieValue, ValidateCookieValue, DeleteCookie, SaveCookie} from '../../Utilities/ADFECookie'
import { GetAuth0UserByEmail, AutologinAPI } from './Auth0ADInterface'

function useProvideUniversalAuth() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState([]);
    const [webAuth, setWebAuth] = useState(null)
    const [environment, setEnvironment] = useState(getEnvironment());
    const [currentAction, setCurrentAction] = useState([]);
    const [returnUrl, setReturnUrl] = useState(null);
    const authProviderType = "auth0"; 

    const params = new Proxy(new URLSearchParams(window.location.search),
        {
            get: (searchParams, props) => searchParams.get(props),
        });

    const getWebAuth = () => {
        if (!webAuth) {
            GetConfiguration("auth0authenticationapiconfiguration", environment).then((configuration) => {
                var wa = new auth0.WebAuth(
                    {
                        domain: configuration.customDomain,
                        clientID: configuration.clientId,
                        responseMode: 'query',
                        responseType: 'code',
                        scope: 'openid profile email offline_access'
                    });
                setWebAuth(wa);
            }).catch((webauthErrors) => {
                errors.push(webauthErrors);
            });
        }
    }

    const PassThruUrl = () => {
        let params = (new URL(document.location)).searchParams;
        return params.get("PassThruUrl");
    }
    
    const EmailId = () => {
        let params = (new URL(document.location)).searchParams;
        return params.get("email") || "";
    }
    const ReturnUrl = () => {
        
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl") || params.get("returl");
        if (retUrl){

            setReturnUrl(retUrl);
            return retUrl;

            } 

        if (!returnUrl ) {
              //return new Promise((resolve, reject) => {
        let params = (new URL(document.location)).searchParams;
        let retUrl = params.get("ReturnUrl") || params.get("returl");;
           
            if (!retUrl) {
                retUrl = document.referrer;
            }
            if (!retUrl) {
                retUrl = sessionStorage.getItem("ReturnUrl");
            }
            if (retUrl) {
                setReturnUrl(retUrl);
                //resolve(returnUrl);
            }
            if (!retUrl) {
                APIGet({
                    "controller": "brand",
                    "action": "getcurrentbrand",
                    "environment": environment
                }).then((response) => {

                    if (response?.data?.brand?.returnurl) { 
                        sessionStorage.setItem("ReturnUrl", response.data.brand.returnurl);
                        setReturnUrl(response.data.brand.returnurl);
                    }
                    //resolve(returnUrl);
                }).
                catch((error) => {
                    //reject(error);
                })
            }
        }
        return returnUrl;
        //})
    }

    const ClearUserData = () => {
        RemoveADToken();
        setUserData(null);
    }

    const AuthenticateWithCookie = () => {
        return new Promise((resolve, reject) => {
            GetCookieName().then((cookieName) => {
                var cookieValue = GetCookieValue(cookieName);
                if (cookieValue) {
                    ValidateCookieValue(cookieValue).then((user) => {
                        if (user) {
                            var userToken = {
                                "name": user.userfullname,
                                "token": user.userToken,
                                "partyid": user.partyid,
                                "username": user.username
                            };
                            SaveADToken(userToken);
                            resolve(userToken);
                        }
                        resolve(null);
                    });
                }
                resolve(null);
            });

        });

    }

    const Login = (username,password) => {
        
    }

    const GotoUniversalLogin = (email) => {
        console.log(email);
        if(!email)
            email = EmailId()
        let returnUrl = ReturnUrl();
        var data = {
            "ReturnUrl": returnUrl,
            "email": email
        };
        
        APIRedirect({
            "controller": "auth0",
            "action": "authenticate",
            "environment": environment,
            data : data
        });
    }

    const ClearAuth0Tokens = (refToken) => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("refToken", refToken);
            APIPost({
                "controller": "auth0",
                "action": "cleartokens",
                "environment": environment,
                data: formData
            }).then((axresult) => {
                resolve(axresult.data.success);
            }).catch((axerror) => {
                reject(axerror);
            });
        });
    }

    const AutoLogin = (refParam) => {
        return new Promise((resolve, reject) => {
            if (currentAction.length == 0) {
                currentAction.push("authenticating");
                var auth0Token = refParam;
                    if (!auth0Token) {
                    auth0Token = GetAuth0TokenLocal();
                }
                if (auth0Token) {
                    AutologinAPI(environment, auth0Token).then((userToken) => {
                        sessionStorage.setItem('authenticatedWithAuth0', 'true');
                        currentAction.pop();
                        resolve(userToken);
                    }).catch((aerrors) => {
                        currentAction.pop();
                        reject(aerrors);
                    });

                }
                else {
                    currentAction.pop();
                    resolve(null);
                }
            }
        });
    }
    
    const getUserToken = () => {
        return new Promise((resolve, reject) => {
            var refToken = params.refToken;
            var userToken = GetADToken();   
            if (isLoading) {
                GetConfiguration("userconfiguration", environment).then((configuration) => {
                    //var checkADFECookie = true;
                    //if (configuration && configuration.authenticationprovidertype == "auth0") {
                    //    var authenticatedWithAuth0 = sessionStorage.getItem('authenticatedWithAuth0');
                    //    checkADFECookie = (authenticatedWithAuth0 && Boolean(authenticatedWithAuth0));
                    //}
                    //if (userToken?.token) {
                    //    RefreshADToken().then((result) => {
                    //        userToken = result;
                    //        resolve(userToken);
                    //    });
                    //}
                    //if (!userToken?.token && checkADFECookie) {
                    //    AuthenticateWithCookie().then((userToken) => {
                    //        if (userToken?.token) {
                    //            resolve(userToken);
                    //        }
                    //    }).catch((aerrors) => {
                    //        reject(aerrors);
                    //    });
                    //}
                    if (!userToken?.token || refToken) {
                        if (configuration && configuration.authenticationprovidertype == "auth0") {
                            AutoLogin(refToken).then((userToken) => {
                                resolve(userToken);
                            }).catch((aerrors) => {
                                reject(aerrors);
                            });
                        }
                        else {
                            resolve(null);
                        }
                    }
                    else {
                        resolve(userToken);
                    }
                }).catch((cerrors) => {
                    reject(cerrors);
                })
            }
            else {
                resolve(userToken);
            }
        });

    }

    const GetLoginData = (environment, refToken) => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "auth0",
                "action": "getuserlogindata",
                "environment": environment,
                "identifier": refToken
            }).then((response) => {
                resolve(response.data.loginData);
            }).catch((errors) => {
                reject(errors);
            });
        })
    }

    const GetADRefToken = () => {
        var refToken = params.refToken;
        if (!refToken) {
            refToken = GetAuth0TokenLocal();
        }
        return refToken;
    }
  
    const GetSocialConnections = () => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "auth0",
                "action": "getsocialconnections",
                "environment": environment
            }).
                then((response) => {
                    var result = response.data;

                    if (result.success) {
                        resolve(result.connections);
                    }
                    else {
                        reject(result.errors);
                    }
                }).
                catch((error) => {
                    var unexpected = []
                    unexpected.push(error)
                    reject(error);
                });
        });

    }

    const CheckEmail = (email) => {
        return new Promise((resolve, reject) => { 
            let page = getPage();
            /*
            * for universal bypass all the waffleflows & go straight to auth0
            */
            if (page === "login")
            {
                GotoUniversalLogin(email);
            }         
            GetAuth0UserByEmail(email, environment).then((auth0response) => {
                if (auth0response.status == 0) {
                    console.log(auth0response);
                    resolve({ status: "email_found" });                     
                }
                else {
                    resolve({ status: "email_not_found" });
                }
            }).catch((errors) => {
                //setErrors(errors);
                reject(errors)
            });             
        });      
    }

    const CheckRegisteredAccount = (email) => {
        return new Promise((resolve, reject) => {
            let page = getPage();
            /*
            * for universal bypass all the waffleflows & go straight to auth0
            */
            if (page === "login") {
                GotoUniversalLogin(email);
            }
            GetAuth0UserByEmail(email, environment).then((auth0response) => {
                if (auth0response.status == 0) {
                    console.log(auth0response);
                    resolve({ status: "email_found" });
                }
                else {
                    resolve({ status: "email_not_found" });
                }
            }).catch((errors) => {
                //setErrors(errors);
                reject(errors)
            });
        });
    }

    const ForwardPage = () => {
       
    }

    function Logout(returnUrl, refToken) {
        GetCookieName().then((cookieName) => {
            DeleteCookie(cookieName);
        });

        if (!refToken) {
            refToken = GetAuth0TokenLocal();
        }
        if (refToken) {
            RemoveADToken();
            ClearAuth0Tokens(refToken).then((result) => {
                RemoveAuth0Token();
            }).catch((error) => {
                setErrors(ProcessErrors(error));
            });
        }
        
        if (!returnUrl) {
            returnUrl = window.location.origin;
        }
       
         APIRedirect({
            "controller": "auth0",
            "action": "logout",
            "environment": environment,
            data : {
                ReturnUrl : returnUrl
            }
        });                     
    }

    const SendForgottenPasswordLink = (email) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('email', email);

            APIPost({
                "controller": "auth0",
                "action": "sendforgottenpasswordlink",
                "environment": environment,
                data: bodyFormData,
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result.errors);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {

                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }

    const ValidatePasswordResetToken = (token) => {
        return new Promise((resolve, reject) => {
            APIPost({
                "controller": "auth0",
                "action": "validateresetpasswordtoken",
                "environment": environment,
                "identifier": token
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    if (errors.length == 0) {
                        result.errors.forEach((error) => {
                            errors.push(error);
                        });
                    }
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((axiosErrors) => {
                errors.push("error.unexpected");
            });
        });
    }

    const ResetPassword = (token, newPassword) => {
        return new Promise((resolve, reject) => {
            var bodyFormData = new FormData();
            bodyFormData.append('newPassword', newPassword);
          
            APIPost({
                "controller": "auth0",
                "action": "resetpassword",
                "environment": environment,
                "identifier": token,
                data: bodyFormData
            }).then((response) => {
                var result = response.data;
                if (!result.success) {
                    errors.push(ProcessErrors(result.errors));
                    reject(result);
                }
                else {
                    resolve(result);
                }
            }).catch((errors) => {
                errors.push((ProcessErrors("error.unexpected")));
            });
        });
    }

    if (isLoading) {
        getUserToken().then((userToken) => {
            setIsLoading(false);
            setUserData(userToken);

        }).catch((error) => {
            errors.push(ProcessErrors("error.unexpected"));
            setIsLoading(false);
        });
    }
    else {
        var sessionToken = GetADToken()
        if (!userData && sessionToken) {
            setUserData(sessionToken);
        }
    }
   
    return {
        authProviderType,
        environment,
        userData,
        isLoading,
        setErrors,
        errors,
        ReturnUrl,
        PassThruUrl,
        ForwardPage,
        CheckEmail,
        CheckRegisteredAccount,
        Login,
        Logout,
        GetADRefToken,
        GetLoginData,
        GetSocialConnections,
        ClearUserData,
        SendForgottenPasswordLink,
        ValidatePasswordResetToken,
        ResetPassword,
        GotoUniversalLogin
    }
}

function Auth0UniversalAuthenticationProvider(props) {
    const AuthContext = props.AuthContext;
    var auth = useProvideUniversalAuth();
    if (!auth || auth.isLoading) {
        return (<Loading loading={true} class={"center-column"} />);
    }
    else {
        return (<AuthContext.Provider value={auth} {...props} />)
    }
}

export {Auth0UniversalAuthenticationProvider}